<template>
  <div>
    <span class="d-block font-24 font-700">{{
      $t("dashboard.wantToReset")
    }}</span>
    <v-form @submit.prevent="resetHandler">
      <dialog-btns
        :closeTitle="$t('forms.cancel')"
        :btnTitle="$t('forms.continue')"
        @close="$emit('close')"
      ></dialog-btns>
    </v-form>
  </div>
</template>

<script>
import DialogBtns from "./DialogBtns.vue";
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: { DialogBtns },
  methods: {
    async resetHandler() {
      let { data } = await this.$axios.post("email/check", {
        email: this.data.email,
      });

      if (data.success) {
        this.$emit('close')
        this.$store.dispatch('setResetCode', data.data)
        this.$router.push('/dashboard/reset-password')
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error",
        });
      }
    },
  },
};
</script>

<style></style>
