<template>
  <div class="relative d-block">
    <v-form v-model="valid" @submit.prevent="updateHandler" ref="clinicInfo">
      <div class="d-flex align-center justify-space-between">
        <span
          class="d-block font-700 darkbord--text"
          :class="isMobile ? 'font-18' : 'font-24 '"
          >{{ $t("forms.editPersonalInformation") }}</span
        >
        <dialog-btns
          v-if="isMobile"
          :topBtns="true"
          :btnTitle="$t('forms.save')"
          :closeTitle="$t('forms.discard')"
          @click="resetHandler"
          @close="$emit('close')"
        ></dialog-btns>
      </div>
      <v-row class="mt-5">
        <!-- first name -->
        <v-col cols="12">
          <base-input
            v-model="obj.name"
            :isRequired="true"
            :placeholder="$t('forms.name')"
            :title="$t('forms.name')"
          ></base-input>
        </v-col>
        <!-- email -->
        <v-col cols="12">
          <base-input
            v-model="obj.email"
            :isRequired="true"
            :placeholder="$t('forms.email')"
            :title="$t('forms.email')"
          ></base-input>
        </v-col>

        <!-- phone number -->
        <v-col cols="12" class="mt-0">
          <v-row class="px-0 mt-0">
            <v-col cols="12" md="6" class="mt-0 pt-0">
              <base-auto-complete
                :isPhone="true"
                placeholder="Lebanon(+961)"
                :items="filteredCountries"
                itemValue="phone_code"
                itemText="name"
                :title="$t('forms.countryCode')"
                v-model="obj.pref_phone"
                :noTitle="false"
                isCountrySelect
              ></base-auto-complete>
            </v-col>
            <v-col cols="12" md="6" class="mt-0 pt-0">
              <base-input
                v-model="obj.phone"
                placeholder="9999999"
                :title="$t('forms.phone')"
                :noTitle="false"
              ></base-input>
            </v-col>
          </v-row>
        </v-col>
        <!-- Country -->
        <v-col cols="6">
          <base-auto-complete
            v-model="obj.country_id"
            :items="dataLists.countries"
            :isRequired="true"
            :placeholder="$t('forms.country')"
            :title="$t('forms.country')"
            @input="getCities"
          ></base-auto-complete>
        </v-col>
        <!-- City -->
        <v-col cols="6">
          <base-auto-complete
            v-model="obj.city_id"
            :items="cities"
            :isRequired="true"
            itemValue="id"
            :placeholder="$t('forms.city')"
            :title="$t('forms.city')"
          ></base-auto-complete>
        </v-col>
        <!-- documantions -->
        <v-col cols="12">
          <div class="upload-file">
            <v-file-input
              multiple
              :placeholder="$t('forms.uploadFile')"
              prepend-icon=""
              hide-details=""
              v-model="documentations"
              @change="fileHandler"
              :rules="[(v) => !!v || 'require']"
            ></v-file-input>
          </div>
        </v-col>
      </v-row>
      <dialog-btns
        v-if="!isMobile"
        :btnTitle="$t('forms.save')"
        :closeTitle="$t('forms.discard')"
        :loading="loading"
        @close="$emit('close')"
      ></dialog-btns>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseInput from "../BaseInput.vue";
import BaseAutoComplete from "../BaseAutoComplete.vue";
import DialogBtns from "./DialogBtns.vue";

export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  components: { DialogBtns, BaseInput, BaseAutoComplete },
  data: () => ({
    dataLists: {},
    obj: {},
    valid: false,
    loading: false,
    documentations: [],
    cities: [],
  }),
  computed: {
    ...mapGetters(["userType", "countries"]),
    filteredCountries() {
      return this.countries.countries.map((item) => {
        return {
          ...item,
          name: item.name + " " + ` (+${item.phone_code}) `,
        };
      });
    },
  },
  methods: {
    fileHandler(files) {
      this.documentations= [];
      let docs = [];
      files.forEach((file) => {
        if (file.size > 2049892) {
          this.$store.dispatch("showSnack", {
            text: this.$t("rules.fileSize"),
            color: "error",
          });
        } else {
          docs.push(file);
        }
      });
      this.documentations = docs;
    },
    
    async getDataLists() {
      let { data } = await this.$axios.get("countries/global");
      if (data) {
        this.dataLists = data.data;
        if (this.info.id) {
          this.dataLists.countries.forEach((country) => {
            if (country.name.toLowerCase() === this.info.country.toLowerCase()) {
              this.obj.country_id = country.id;
            }
            // country.cities.forEach((city) => {
            //   if (city.name == this.obj.city) {
            //     this.obj.city_id = city.id;
            //   }
            // });
          });
        }
      }
    },
    async getCities() {
      let formData = new FormData();
      formData.append("country_id", this.obj.country_id);
      let { data } = await this.$axios.post("cities/global", formData);
      if (data) {
        this.cities = data.data;
        this.cities.forEach((city) => {
          if (city.id == this.obj.city_id) {
            this.obj.city_id = city.id;
          }
          // country.cities.forEach((city) => {
          //   if (city.name == this.obj.city) {
          //     this.obj.city_id = city.id;
          //   }
          // });
        });
      }
    },
    async updateHandler() {
      let valid = this.$refs.clinicInfo.validate();
      if (!valid) {
        setTimeout(() => {
          document
            .querySelector(".v-input.error--text:first-of-type")
            .scrollIntoView({
              behavior: "smooth",
            });
        }, 100);

        this.$store.dispatch("showSnack", {
          text: this.$t("rules.checkAllFields"),
          color: "error",
        });
        return;
      }
      this.loading = true;
      let url = "profile/update";
      let formData = new FormData();

      formData.append("name", this.obj.name);
      formData.append("email", this.obj.email);
      formData.append("pref_phone", "+" + this.obj.pref_phone);
      formData.append("phone", this.obj.phone);
      formData.append("country_id", this.obj.country_id);
      formData.append("city_id", this.obj.city_id);

      this.documentations.forEach((file) => {
        formData.append(`documentations[]`, file);
      });

      let { data } = await this.$axios.post(url, formData, {
        headers: {
          Accept: "application/json",
        },
      });
      this.loading = false;
      if (data.success) {
        this.$store.dispatch("showSnack", {
          text: "Updated Successfully",
          color: "success",
        });
        this.$emit("getData");
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error",
        });
      }
    },
  },
  async created() {
    this.obj = { ...this.info };
    this.obj.pref_phone = this.obj.pref_phone.replace("+", "");
    await this.getDataLists();
    await this.getCities();
  },
};
</script>

<style lang="scss">
.upload-file {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background: $lightgreen;
  padding-left: 15px;
  padding-block: 10px;

  .v-input {
    padding-top: 0px;

    .v-input__slot {
      &::after,
      &::before {
        display: none;
      }
    }
  }

  .icon {
    position: absolute;
    top: 0px;
    right: 0px;
    background: $lightblue;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 60px;
  }
}
</style>
