<template>
    <div class="dashboard-profile white radius-15 pa-2 pb-4 bluebgdash">
        <profile-head
                :title="$t('dashboard.personalInfo')"
                :btnTitle="$t('dashboard.editPersonalInfo')"
                :icon="require('@/assets/images/dashboard/icons/edit.svg')"
                @click="infoDialog = true"
        >
        </profile-head>

        <!-- main info -->
        <div class="mt-3 white px-4 py-4">
      <span class="d-block font-700 font-18">{{
          $t("dashboard.personalInfo")
          }}</span>
            <div class="mt-6 px-2" :class="isMobile ? '' : 'd-flex'">
                <div class="user-image radius-10 relative mx-5">
                    <img
                            class="object-cover"
                            width="32px"
                            height="32px"
                            :src="require('@/assets/images/icons/hospital.svg')"
                            alt=""
                    />
                </div>
                <v-row>
                    <v-col lg="2" md="4" sm="6" cols="12" class="px-2 py-1">
                        <base-info
                                :title="$t('forms.customer')"
                                :value="
                userType == 'doctor'
                  ? data.first_name + ' ' + data.last_name
                  : data.name
              "
                        ></base-info>
                    </v-col>
                    <v-col lg="2" md="4" sm="6" cols="12" class="px-2 py-1">
                        <base-info
                                :title="$t('forms.phone')"
                                :value="data.phone"
                        ></base-info>
                    </v-col>
                    <v-col lg="2" md="4" sm="6" cols="12" class="px-2 py-1">
                        <base-info
                                :title="$t('forms.email')"
                                :value="data.email"
                        ></base-info>
                    </v-col>
                    <v-col lg="2" md="4" sm="6" cols="12" class="px-2 py-1 text-center">
                        <base-info
                                :title="$t('forms.nationality')"
                                :value="userType == 'doctor' ? data.nationality : data.country"
                        ></base-info>
                    </v-col>
                    <v-col lg="1" md="4" sm="6" cols="12" class="px-2 py-1">
                        <base-info
                                v-if="userType == 'doctor'"
                                :title="$t('forms.gender')"
                                :value="data.gender"
                        ></base-info>
                        <base-info
                                v-if="userType != 'doctor'"
                                :title="$t('forms.city')"
                                :value="data.city"
                        ></base-info>
                    </v-col>
                    <v-col lg="3" md="4" sm="6" cols="12" class="px-2 py-1">
                        <base-info
                                v-if="userType == 'doctor'"
                                :title="$t('forms.specializations')"
                                :value="data.specialization"
                        ></base-info>
                    </v-col>
                </v-row>
            </div>
        </div>

        <!-- buttons -->
        <div class="d-flex align-center mt-6">
            <div
                    class="lightregister-primary px-7 py-2 radius-5 cursor-pointer"
                    @click="resetDialog = true"
            >
        <span class="font-16 font-600 darkprimary--text">{{
            $t("dashboard.resetPassword")
            }}</span>
            </div>
        </div>

        <!-- documents -->
        <div class="px-2 mt-5 mb-3">

      <span class="d-block font-20 font-600 black--text">{{
          $t("dashboard.documents")
          }}</span>
        </div>
        <v-row class="px-2" v-if="userType == 'clinic'">
            <v-col
                    cols="12"
                    sm="6"
                    md="3"
                    lg="2"
                    v-for="(item, i) in data.documentations"
                    :key="i"
                    class="pa-2"
            >
                <div
                        class="document px-3 py-4 flex-between-column"
                        :class="isMobile ? 'd-flex align-center justify-space-between' : ''"
                >
          <span class="font-16 font-600 lightgreytext--text d-block">{{
              item
              }}</span>
                    <div>
            <span class="font-16 d-block mt-2 uppercase">{{
                item.split(".")[6]
                }}</span>
                        <v-btn
                                color="#96D4C9"
                                dark
                                elevation="0"
                                :block="!isMobile"
                                @click="showHandler(item)"
                                outlined
                                max-height="40px"
                        >
                            <span class="font-16">{{ $t("dashboard.showDocument") }}</span>
                        </v-btn>
                        <v-btn
                                color="error"
                                dark
                                elevation="0"
                                :block="!isMobile"
                                @click="deleteHandler(i)"
                                outlined
                                class="mt-3"
                                max-height="40px"
                        >
                            <span class="font-16">{{ $t("dashboard.deleteDocument") }}</span>
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>

        <!-- other info -->
        <div class="mt-4 white px-4 py-6" v-if="userType == 'doctor'">
            <div class="text-right mb-4" v-if="isMobile">
                <div
                        class="d-flex align-center justify-center radius-5 cursor-pointer w-max ml-auto"
                        @click="futureDialog = true"
                >
                    <img :src="require('@/assets/images/dashboard/icons/edit.svg')"/>
                </div>
            </div>
            <div class="d-flex">
                <v-row>
                    <v-col
                            :lg="info.lg"
                            :md="info.md"
                            :cols="12"
                            v-for="(info, i) in otherInfos"
                            class="py-1"
                            :key="i"
                    >
                        <base-info
                                v-if="!info.isBtn"
                                :title="info.title"
                                :value="info.value"
                        ></base-info>

                        <div
                                class="lightregister-primary px-8 py-2 d-flex align-center justify-center radius-5 cursor-pointer w-max ml-auto"
                                v-if="info.isBtn && !isMobile"
                                @click="futureDialog = true"
                        >
                            <img
                                    class="mx-2"
                                    :src="require('@/assets/images/dashboard/icons/edit.svg')"
                            />
                            <span class="font-16 font-600 darkprimary--text">{{
                                $t("forms.edit")
                                }}</span>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>

        <!-- reset password -->
        <v-dialog
                v-model="resetDialog"
                content-class="pa-8 white radius-15"
                max-width="450px"
        >
            <reset-password
                    @close="resetDialog = false"
                    :data="data"
            ></reset-password>
        </v-dialog>

        <!-- personal infos -->
        <v-dialog
                v-model="infoDialog"
                :content-class="isMobile ? 'pa-4 radius-5 white' : 'pa-6 white radius-16'"
                max-width="600px"
                :key="infoDialog"
        >
            <clinic-info
                    v-if="userType != 'doctor'"
                    @getData="getData"
                    :info="data"
                    @close="infoDialog = false"
                    :key="infoDialog"
            ></clinic-info>
            <personal-infos
                    v-if="userType == 'doctor'"
                    @getData="getData"
                    :info="data"
                    @close="infoDialog = false"
            ></personal-infos>
        </v-dialog>

        <!-- future job -->
        <v-dialog
                v-model="futureDialog"
                :content-class="isMobile ? 'pa-4 radius-5 white' : 'pa-6 white radius-16'"
                max-width="600px"
        >
            <future-job
                    @close="futureDialog = false"
                    :info="data"
                    @getData="getData"
            ></future-job>
        </v-dialog>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import BaseInfo from "../../../components/BaseInfo.vue";
import ClinicInfo from "../../../components/dialogs/ClinicInfo.vue";
import FutureJob from "../../../components/dialogs/FutureJob.vue";
import PersonalInfos from "../../../components/dialogs/PersonalInfos.vue";
import ResetPassword from "../../../components/dialogs/ResetPassword.vue";
import ProfileHead from "../../../components/ProfileHead.vue";
import i18n from "@/i18n";

export default {
    components: {
        ProfileHead,
        BaseInfo,
        ResetPassword,
        PersonalInfos,
        FutureJob,
        ClinicInfo,
    },
    data: (vm) => ({
        otherInfos: [
            {
                title: vm.$t("forms.futureJobType"),
                value: "",
                md: "2",
                lg: "2",
                sm: "4",
                cols: "6",
                isBtn: false,
            },
            {
                title: vm.$t("forms.countryOfResidence"),
                value: "",
                md: "2",
                lg: "2",
                sm: "4",
                cols: "6",
                isBtn: false,
            },
            {
                title: vm.$t("forms.futureJobLocation"),
                value: "",
                md: "2",
                lg: "2",
                sm: "4",
                cols: "6",
                isBtn: false,
            },
            {
                title: vm.$t("forms.expectedMonthlySalary"),
                value: "",
                md: "3",
                lg: "3",
                sm: "4",
                cols: "6",
                isBtn: false,
            },
            {
                title: vm.$t("forms.dataCheck"),
                value: "",
                md: "1",
                lg: "1",
                sm: "4",
                cols: "6",
                isBtn: false,
            },
            {
                title: "",
                value: "",
                md: "2",
                lg: "2",
                sm: "4",
                cols: "6",
                isBtn: true,
            },
        ],

        resetDialog: false,
        infoDialog: false,
        futureDialog: false,
        data: {},
    }),
    computed: {
        ...mapGetters(["userType", "countries"]),
    },
    methods: {
        showHandler(item) {
            window.open(item, "_blank");
        },
        logoutHandler() {
            this.$store.dispatch("logout");
        },
        async deleteHandler(index) {
            this.data.documentations = this.data.documentations.filter(
                (_, idx) => idx != index
            );
            let formData = new FormData();
            this.data.documentations.forEach((file) => {
                formData.append(`documentations[]`, file);
            });
            let {data} = await this.$axios.put("profile/update", formData, {
                headers: {
                    Accept: "application/json",
                },
            });
            if (data.success) {
                this.$store.dispatch("showSnack", {
                    text: "documentation deleted Successfully",
                    color: "success",
                });
                await this.getData();
            } else {
                this.$store.dispatch("showSnack", {
                    text: "Something went wrong",
                    color: "error",
                });
            }
        },
        async getData() {
            this.infoDialog = false;
            this.futureDialog = false;
            let url;
            if (localStorage.getItem("role") == "doctor") {
                url = "information";
            } else {
                url = "info";
            }
            let {data} = await this.$axios.get(url);
            if (data.success) {
                console.log(this.data.toString())
                this.data = data.data;
                this.otherInfos[0].value = this.data.job_type;
                this.otherInfos[1].value = this.data.country;
                this.otherInfos[2].value =
                    this.data.future_job_location[0] &&
                    this.data.future_job_location[0].name;
                this.otherInfos[3].value = this.data.expected_salary_usd;
                this.otherInfos[4].value = this.data.datacheck !== "" ? i18n.t('global.yes') : i18n.t('global.no');

                this.data.specializations = data.data.specializations.map(
                    (item) => item.id
                );
            }
        },
        async updateImage(event) {
            let url;
            if (this.userType == "doctor") {
                url = "profile";
            } else {
                url = "hospital/profile";
            }
            let formData = new FormData();
            formData.append("profile", event);

            let {data} = await this.$axios.post(url, formData, {
                headers: {
                    Accept: "application/json",
                },
            });

            if (data.success) {
                this.$store.dispatch("showSnack", {
                    text: "Profile image updated Successfully",
                    color: "success",
                });
                await this.getData();
            } else {
                this.$store.dispatch("showSnack", {
                    text: "Something went wrong",
                    color: "error",
                });
            }
        },
    },
    async created() {
        await this.getData();
    },
};
</script>

<style lang="scss">
.dashboard-profile {
  min-height: calc(100vh - 160px);

  .user-image {
    &__file {
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      background: #7575758f;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      filter: blur(0.6px);

      .file {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: block;
        margin: 0px;

        div,
        button {
          width: 100%;
          height: 100%;
        }

        .v-input__prepend-outer {
          margin: 0px;
        }
      }
    }
  }

  .document {
    background: white;
    border-radius: 6px;
    overflow-wrap: break-word;
    min-height: 230px;
    transition: 0.4s;

    &:hover {
      box-shadow: 0px 17px 31px 0px #87e5d54d;
    }

    .v-btn {
      transition: 0.3s;

      span {
        transition: 0.3s;
      }

      &:hover {
        background: $lightblue;

        span {
          color: white;
        }
      }
    }
  }

  @media (max-width: 960px) {
    .user-image {
      overflow: hidden;
      margin: auto !important;
      margin-bottom: 30px !important;
      text-align: center;
      position: relative;
      width: 100px;
      border-radius: 50%;
      height: 100px;

      img {
        margin: auto;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }
}
</style>
